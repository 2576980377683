<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- id -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" disabled />
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" label="company" :error="error.companyId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- html -->
        <n-field-boolean v-model="model.html" label="html" :error="error.html" :disabled="disabled" />
        <!-- language -->
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col1>
        <!-- i18nKey -->
        <n-field-text v-model="model.i18nKey" field-name="i18nKey" label="i18nKey" :error="error.i18nKey" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <!-- i18nValue -->
        <n-text-editor v-model="model.i18nValue" :html="model.html" field-name="i18nValue" label="i18nValue" :error="error.i18nValue" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, add, edit } from '@/api/company/company-translation';

export default NModal.extend({
  name: 'ModalCompanyTranslation',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
